import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingPageComponent } from "./landing/landing.component";
import { AccountDeletionComponent } from "./account/account-deletion/account-deletion.component";
import { ReceiptComponent } from "./receipt/receipt.component";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { LayoutComponent } from "./layout/layout.component";
import { SiteLandingComponent } from "./site-landing/site-landing.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { CustomerContextComponent } from "./customer-context/customer-context.component";
import { ResetPwdComponent } from "./reset-password/reset-password.component";
import { CampaignInstanceLandingComponent } from "./campaign-instance-landing/campaign-instance-landing.component";

const routes: Routes = [
  {
    path:'',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent
      },
      {
        path: 'customer/:customerId',
        component: CustomerContextComponent,
        children: [
          {
            path: '',
            component: SiteLandingComponent
          },
          {
            path: 'passes',
            loadChildren: () => import('./pass-purchase/pass-purchase.module').then(m => m.PassPurchaseModule)
          },
          {
            path: 'login',
            component: LoginComponent
          },
          {
            path: 'signup',
            component: SignupComponent
          },
          {
            path: 'reset-password',
            component: ResetPwdComponent
          },
          {
            path: 'manage-my-membership',
            loadChildren: () => import('./manage-my-membership/manage-my-membership.module').then(m => m.ManageMyMembershipModule)
          },
          {
            path: 'contact-us',
            component: ContactUsComponent
          },
          {
            path: 'terms-of-service',
            component: TermsOfServiceComponent
          }
        ]
      },
      
    ]
  },
  {
    path: "delete/account",
    component: AccountDeletionComponent
  },
  {
    path: "receipt/:id",
    component: ReceiptComponent
  },
  {
    path: "site/:siteId/instance-landing/:campaignId/:instanceId",
    component: CampaignInstanceLandingComponent
  }
 
]

@NgModule({
  imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'enabled', useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
