import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LandingPageComponent } from "./landing/landing.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModalModule, BsModalService } from "ngx-bootstrap/modal";
import { BsDropdownModule, BsDropdownConfig } from "ngx-bootstrap/dropdown";
import { AccountDeletionComponent } from './account/account-deletion/account-deletion.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppSettingsModule } from "./app-settings/app-settings.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { LayoutComponent } from './layout/layout.component';
import { SiteLandingComponent } from './site-landing/site-landing.component';
import { SharedModule } from "./shared/shared.module";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { CustomerContextComponent } from './customer-context/customer-context.component';
import { PassPurchaseService } from 'src/services/pass-purchase.service';
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { CommonModule } from "@angular/common";
import { ResetPwdComponent } from "./reset-password/reset-password.component";
import { CampaignInstanceLandingComponent } from "./campaign-instance-landing/campaign-instance-landing.component";


export function playerFactory() { 
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    AccountDeletionComponent,
    ReceiptComponent,
    TermsOfServiceComponent,
    LayoutComponent,
    SiteLandingComponent,
    ContactUsComponent,
    LoginComponent,
    SignupComponent,
    ResetPwdComponent,
    CustomerContextComponent,
    CampaignInstanceLandingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    FontAwesomeModule,
    AppSettingsModule.forRoot(),
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    [LottieModule.forRoot({player: playerFactory})]
  ],
  providers: [ 
    PassPurchaseService,
    BsDropdownModule,
    ModalModule,
    BsDropdownConfig,
    BsModalService
],
    
  bootstrap: [AppComponent],
})
export class AppModule {}
