import { Injectable, SecurityContext } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, firstValueFrom, Observable, throwError, of } from "rxjs";
import { ISiteProduct } from "src/models/site-product";
import { catchError, map } from "rxjs/operators";
import { IConsumer } from "src/models/consumer";
import { ICustomerPass, ICustomerPassWithSiteProducts } from "src/models/customer-pass";
import { DomSanitizer } from "@angular/platform-browser";
import { IPassPaymentAdditionalInfo, WNPaymentResponse } from "src/models/worldnet-models";
import { IPromo } from "src/models/promo";
import { CustomerInformation, CustomerSiteInfo } from "src/models/customer";
import { Router } from "@angular/router";
import { ConsumerLogin } from "src/models/consumer-login";
import { TextReceiptInformation } from "src/models/text-receipt-information";
import { AppSettingsService } from "src/app/app-settings/app-settings.service";
import { SiteInformation, ModalSiteInfo } from "src/models/site-information";
import { IPostGiftPassPurchase, IPostPassPurchase } from "src/models/api";
import { IPassPurchaseDetails } from "src/models/purchase-details";
import { Addresses } from "src/models/addresses";
import { AddressType } from "src/app/enumurations/address-type.enum";
import { ConsumerDetails } from "src/models/consumer-details";
import { CustomerDetails } from "src/models/customer-details";
import { Payment } from "src/models/payment";
import { CreditCard } from "src/models/credit-card";
import { request } from "http";
import { prettyPhoneNumber } from "src/app/utils/string-helpers";
import { text } from "stream/consumers";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private readonly urlBase: string;
  readonly mapsApiKey: string;
  public siteId: string;
  public customerId: Observable<string>;

  private userSubject: BehaviorSubject<ConsumerLogin | null>;
  public user$: Observable<ConsumerLogin | null>;

  apiLoaded: Observable<boolean>;

  public customerInfoSubject = new BehaviorSubject<CustomerInformation>(null);
  public customer$ = this.customerInfoSubject.asObservable();
  public textReceipt = new BehaviorSubject<TextReceiptInformation>(null);
  public receipt$ = this.textReceipt.asObservable();
  public consumerCustomerListSubject = new BehaviorSubject<CustomerInformation[]>(null);
  public consumerCustomerList$ = this.consumerCustomerListSubject
  public modalSiteInfo = new BehaviorSubject<ModalSiteInfo[]>(null);
  public modalSites$ = this.modalSiteInfo.asObservable();
  
  public startTime: number;

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private appSettingsService: AppSettingsService,)
    {
    this.urlBase =`${this.appSettingsService.apiUrl}/${this.appSettingsService.apiVersion}`;
    this.mapsApiKey =`${this.appSettingsService.googleMapsKey}`;

    this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
    this.user$ = this.userSubject.asObservable();

    this.startTime = Date.now();
  }

  public get consumerValue() {
    return this.userSubject.value;
  }

  login(phoneNumber: string, password: string, customerId: string){
    const body = {
      EmailOrPhoneNumber: phoneNumber,
      Password: password,
    };
    return this.http.post<ConsumerLogin>(`${this.urlBase}/Token/login/${customerId}/consumer`, body)
    .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
    }));
  }

//--- Password change endpoints ---//
  // Sends two factor auth token needed for resetPassword()
  sendPasswordAuthToken(customerId: string, phoneNumber: string){
    if(phoneNumber.length > 10){
      phoneNumber = phoneNumber.replace(/^\+[0-9]/, '')
    }
    console.log("Sending token to ", phoneNumber)
    return this.http.get(`${this.urlBase}/Token/passwordreset/${customerId}/consumer?EmailOrPhoneNumber=${phoneNumber}`);
  }

  // Uses two factor authentication. For forgotten password scenarios in reset-password page
  resetPassword(phoneNumber: string, password: string, confirmPassword: string, customerId: string, tfaToken?:string){
    console.log("reset called")
    const body = {
      token: tfaToken,
      EmailOrPhoneNumber: phoneNumber,
      Password: password,
      ConfirmPassword: confirmPassword
    };
    return this.http.post(`${this.urlBase}/Token/passwordreset/${customerId}/consumer`, body);
  }

  // 2fa for hosted secure card endpoint
  sendCard2faToken(){
    let tokenIndex = document.cookie.indexOf('token=')+6;
    let token = document.cookie.substring(tokenIndex, document.cookie.indexOf(';',tokenIndex));

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'authorization': 'Bearer ' + token
    }
    
    const requestOptions = {
      headers: new HttpHeaders(headerDict), 
    };

    return this.http.get(`${this.urlBase}/Customer/sendtwofactorauthenticate`, requestOptions);
  } 

  // Uses the existing password. For updating password in account page
  changePassword(token, consumerId: string, currentPassword: string, newPassword: string, confirmPassword: string){
    const body = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    }

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'authorization': 'Bearer ' + token
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    console.log("Sending update with body", body)
    return this.http.post(`${this.urlBase}/Consumer/${consumerId}/changepassword`, body, requestOptions);
  }

  signUp(phoneNumber: string, customerId: string){
    return this.http.get<IConsumer>(`${this.urlBase}/Consumer/${phoneNumber}/${customerId}/accountcheck`);
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['']);
  }

  getConsumerByNumber(customerId: string, phoneNumber: string) {
    return this.http.get<IConsumer>(`${this.urlBase}/Consumer/${phoneNumber}/${customerId}/accountcheck`);
  }

  getConsumerDetails(customerId: string, phoneNumber: string) {
    return this.http.get<ConsumerDetails>(`${this.urlBase}/Dashboard/customer/${customerId}/consumer/${phoneNumber}`);
  }

  getCustomerDetails(customerId: string, signedURL: string) {
    return this.http.get<CustomerDetails>(`${this.urlBase}/Dashboard/customerdetails/${customerId}/${signedURL}`);
  }

  async getConsumerDetailsAsync(customerId: string, phoneNumber: string) {
    return await firstValueFrom(this.http.get<ConsumerDetails>(`${this.urlBase}/Dashboard/customer/${customerId}/consumer/${phoneNumber}`));
  }

  async getCustomerDetailsAsync(customerId: string, signedURL: string) {
    return await firstValueFrom(this.http.get<CustomerDetails>(`${this.urlBase}/Dashboard/customerdetails/${customerId}/${signedURL}`));
  }

  consumerCustomers(phoneNumber: string){
    const body = {
      MobileNumber: phoneNumber,
      email: "",
    };
    return this.http.post<CustomerInformation[]>(`${this.urlBase}/customer/customerlist`, body).pipe(
      map(customers =>{
        this.consumerCustomerListSubject.next(customers);
        return customers;
      })
    );
  }

  getPurchaseHistory(consumerId: string, token: string, consumerPassId?: string | null){
    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);

    const headerDict = {
        'authorization': 'Bearer ' + token
    }
    
    var body = {}
    if (consumerPassId != null)
    {
      body = {passid: consumerPassId};
    }
    return this.http.get<Payment[]>(`${this.urlBase}/Payment/consumer/${consumerId}/history/`, {
      headers:new HttpHeaders(headerDict),
      params: body,
    })
  }

  updateConsumer(id: string, consumer: Partial<IConsumer>) {
    return this.http.put<IConsumer>(`${this.urlBase}/Consumer/${id}`, consumer)
  }

  deleteConsumer(id: string, token: string){
    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);

    const headerDict = {
        'authorization': 'Bearer ' + token
    }
    
    const requestOptions = {
        headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<IConsumer>(`${this.urlBase}/Consumer/${id}/deleteConsumer`, requestOptions);
  }

  consumerDeleteRequest(consumerId: string){
    var body = {};
    return this.http.post(`${this.urlBase}/consumer/requestingDeletion/${consumerId}`, body);
  }

  getSiteProducts(siteId: string, getImages?: boolean): Observable<ISiteProduct[]> {
    this.siteId = siteId;
    let httpParams = new HttpParams();
    if (getImages != null) {
      httpParams = httpParams.append("getImages", getImages.toString());
    }
    return this.http
      .get<ISiteProduct[]>(`${this.urlBase}/sites/${siteId}/products`, {
        params: httpParams,
      })
      .pipe(
        map((response: ISiteProduct[]) => {
          response.sort((a, b) => b.price - a.price);
          for (let i = response.length - 1; i >= 0; i--) {
            let siteProduct = response[i];
            siteProduct.modified = new Date(siteProduct.modified);
            siteProduct.timestamp = new Date(siteProduct.timestamp);
            siteProduct.productDescriptions.sort(
              (a, b) => a.descriptionOrder - b.descriptionOrder
            );
          }
          return response;
        })
      );
  }

  getEnvironmentCustomerId() {
    return this.customerId;
  }
  private calculateTotalPassCost(pass: ICustomerPass): number {
    return pass.passCost + (pass.passCostCents / 100);
  }

  getSiteCustomerPasses(
    siteId: string,
    kioskVisible?: boolean,
    mobileVisible?: boolean
  ): Observable<ICustomerPass[]> {
    let httpParams = new HttpParams();
    if (kioskVisible != null) {
      httpParams = httpParams.append("kioskVisible", kioskVisible.toString());
    }
    if (mobileVisible != null) {
      httpParams = httpParams.append("mobileVisible", mobileVisible.toString());
    }

    return this.http
      .get<ICustomerPass[]>(
        `${this.urlBase}/customerpass/${siteId}/site`,
        { params: httpParams }
      )
      .pipe(
        map((response) => {
          response.forEach(pass => {
            pass.passCost = this.calculateTotalPassCost(pass);
          });
          response.sort((a, b) => b.passCost - a.passCost);
          for (let i = response.length - 1; i >= 0; i--) {
            let customerPass = response[i];
            customerPass.modified = new Date(customerPass.modified);
            customerPass.timestamp = new Date(customerPass.timestamp);
            for (let j = customerPass.sites.length - 1; j >= 0; j--) {
              let site = customerPass.sites[j];
              site.modified = new Date(site.modified);
              site.timestamp = new Date(site.timestamp);
            }
            for (let j = customerPass.products.length - 1; j >= 0; j--) {
              let product = customerPass.products[j];
              product.modified = new Date(product.modified);
              product.timestamp = new Date(product.timestamp);
            }
            for (let j = customerPass.limits.length - 1; j >= 0; j--) {
              let limit = customerPass.limits[j];
              for (let k = limit.timeRanges.length - 1; k >= 0; k--) {
                let timeRange = limit.timeRanges[k];
                timeRange.start = new Date(timeRange.start);
                timeRange.end = new Date(timeRange.end);
              }
            }
          }
          return response;
        }));
  }

  getCustomerPass(id: string): Observable<ICustomerPassWithSiteProducts> {
    return this.http.get<ICustomerPassWithSiteProducts>(`${this.urlBase}/customerpass/${id}`);
  }

  getSiteImage(siteId?: string): Observable<any> {
    if(siteId){
      return this.http.get(`${this.urlBase}/sites/${siteId}/image`);
    }else{
      return this.http.get(`${this.urlBase}/sites/${this.siteId}/image`);
    }
  }

  async getTaxAndSurcharge(passId: string): Promise<[number, number]>{
    let siteId = sessionStorage.getItem('siteId')
    var tax: number;
    var surcharge: number;

    const currentSite = await (await firstValueFrom(this.http.get<any[]>(`${this.urlBase}/CustomerPass/${siteId}/site`))).find(
      (element) => element.id === passId).sites.find(
        (site) => site.id === siteId);

    tax = currentSite.applyTax ? currentSite.tax : null;
    surcharge = currentSite.applySurcharge ? currentSite.surcharge : null;
    return [tax, surcharge];
  } 

  getDescriptionImage(pictureKey: string): Observable<Blob> {
    return this.http.get(`${this.urlBase}/sites/descriptions/${pictureKey}`, {
      responseType: "blob",
    });
  }

  getCustomerInformation(customerId: string): Observable<CustomerInformation> {
    return this.http.get<CustomerInformation>(`${this.urlBase}/UniversalPassSales/customerinfo/${customerId}`);
  }

  getCustomerSites(customerId: string): Observable<SiteInformation[]>{
    return this.http.get<SiteInformation[]>(`${this.urlBase}/sites/${customerId}/all`);
  }

  getCustomerSite(siteId: string): Observable<SiteInformation>{
    return this.http.get<SiteInformation>(`${this.urlBase}/sites/${siteId}`);
  }

  setTextReceiptInformation(id: string): Observable<TextReceiptInformation> {
    return this.http.get<TextReceiptInformation>(`${this.urlBase}/Payment/${id}/textreceiptinfo`);
  }


  getModalSites(latitude: number, longitude: number): Observable<ModalSiteInfo[]> {
    return this.http.get<ModalSiteInfo[]>(`${this.urlBase}/Dashboard/consumer/${latitude}/${longitude}`);
  }

  /** Creates and returns a partial consumer account. If a consumer account already exists, just returns the account. */
  safeCreatePartialAccount(
    customerId: string,
    phoneNumber: string,
    customerPassId: string
  ): Observable<IConsumer> {
    const body = {
      mobileNumber: phoneNumber,
      customerPassId: customerPassId,
    };
    return this.http.post<IConsumer>(
      `${this.urlBase}/consumer/${customerId}/createpartialweb`,
      body
    );
  }

  createAccount(customerId: string, consumer: any)
  {
    const address: Addresses = {
      street: consumer.street,
      city: consumer.city,
      stateOrProvince: consumer.stateOrProvince,
      zip: consumer.zip,
      country: consumer.country,
      addressType: AddressType.current
    }
    var addresses = []
    addresses.push(address)
    const body = {
      email: consumer.email,
      firstName: consumer.firstName,
      lastName: consumer.lastName,
      mobileNumber: consumer.mobileNumber,
      password: consumer.password,
      confirmPassword: consumer.confirmPassword,
      addresses: addresses,
    }
    return this.http.post<IConsumer>(`${this.urlBase}/consumer/${customerId}/create`, body);
  }

  getConsumer(id: string) {
    return this.http.get<IConsumer>(`${this.urlBase}/consumer/${id}/getConsumer`);
  }

  cancelPass(id: string, token: string){
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'authorization': 'Bearer ' + token
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.put(`${this.urlBase}/ConsumerPass/${id}/cancel`, {}, requestOptions);
  }

  getHostedPaymentPage(
    receiptUrl: string,
    customerId: string,
    price: number,
    additional: IPassPaymentAdditionalInfo,
    siteId: string
  ) {
    console.log("hosted page with", siteId)
    const isTrusted = true;
    console.log("Additional info:", additional)
    const body = {
      consumerId: '4',
      price: price,
      receiptUrl: `${receiptUrl}/#/receipt/webPass`,
      additionalInfo: additional,
      siteId: siteId
    };

    const url = `${this.urlBase}/customer/hpprequest/${customerId}`;

    return this.fetchStaticHTML(url, isTrusted, "POST", body);
  }
  
  getHostedSecureCard(customerId: string, consumerId: string, tfaCode: string){
    const tokenIndex = document.cookie.indexOf('token=')+6;
    const token = document.cookie.substring(tokenIndex, document.cookie.indexOf(';',tokenIndex));
    console.log("Code", tfaCode);
    console.log("Token?", document.cookie['token']);
    
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'responseType': 'text'
    }
    
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    
    return this.http.post(`${this.urlBase}/Customer/hscrequest/${customerId}?consumerId=${consumerId}&code=${tfaCode}`, {}, requestOptions);
    // let hscResponse = await firstValueFrom(this.http.post(`${this.urlBase}/Customer/hscrequest/${customerId}?consumerId=${consumerId}&code=${tfaCode}`, {}, requestOptions)) as string;
    // console.log("Initial HSCResponse", hscResponse);

    // return this.http.get(hscResponse, {headers: {"User-Agent": "dotnetcore"}});
  }
  
  removePaymentCard(consumerId: string, ccid: string){
    let tokenIndex = document.cookie.indexOf('token=')+6;
    let token = document.cookie.substring(tokenIndex, document.cookie.indexOf(';', tokenIndex));
    console.log(ccid, 'and', token)
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'responseType': 'text'
    }
    
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.delete(`${this.urlBase}/Consumer/${consumerId}/cctoken/${ccid}`, requestOptions);
  }

  getCCSummary(consumerId: string, token: string) {
    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);
    
    const headerDict = {
      'authorization': 'Bearer ' + token
    }
    
    const requestOptions = {
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<CreditCard>(`${this.urlBase}/Consumer/${consumerId}/ccsummary`, requestOptions)
  }

  getAllCards(consumerId: string){
    let tokenIndex = document.cookie.indexOf("token=")+6;
    let token = document.cookie.substring(tokenIndex, document.cookie.indexOf(';', tokenIndex));

    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);
    
    const headerDict = {
      'authorization': 'Bearer ' + token
    }
    
    const requestOptions = {
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.get(`${this.urlBase}/Payment/${consumerId}/creditcards`, requestOptions);
  }

  async validatePromo(
    code: number,
    phoneNumber: string,
    customerId: string
  ): Promise<IPromo> {
      const url = `${this.urlBase}/promos/ValidatePromo`;
      const body = {
        customerId: customerId,
        code: code,
        phoneNumber: phoneNumber,
      };
      return await firstValueFrom(this.http.post<IPromo>(url, body));
  }

  async redeemPromo(code: number, phoneNumber: string, customerId: string) {
      const url = `${this.urlBase}/promos/RedeemPromo`;
      const body = {
        customerId: customerId,
        code: code,
        phoneNumber: phoneNumber,
      };
    return await firstValueFrom(this.http.post<IPromo>(url, body));
  }

  private handlePostError(err: HttpErrorResponse){
    console.error(err.error);
    return throwError(() => new Error('Request failed at post'))
  }

  async postPassPurchase(
    siteId: string,
    receipt: WNPaymentResponse,
    customerPassId: string,
    thankYouURL: string,
    consumerId?: string,
  ) {
    const body: IPostPassPurchase = {
      customerPassId: customerPassId,
      consumerId: consumerId,
      siteId: siteId,
      wnOrderId: receipt.ORDERID,
      wnCardNumber: receipt.CARDNUMBER,
      wnCardType: receipt.CARDTYPE,
      wnUniqueRef: receipt.UNIQUEREF,
      wnResponseCode: receipt.RESPONSECODE,
      wnResponseText: receipt.RESPONSETEXT,
      wnApprovalCode: receipt.APPROVALCODE.substring(0, 2),
      amount: receipt.AMOUNT,
      promoId: receipt.PROMOID,
      promoCode: receipt.PROMOCODE,
      tax: receipt.TAX,
      surcharge: receipt.SURCHARGE,
      cardReference: receipt.CARDREFERENCE,
      cardExpiry: receipt.CARDEXPIRY,
      thankYouURL: thankYouURL,
      mobileNumber: receipt.PHONE
    };
    
    return await firstValueFrom(this.http.post(`${this.urlBase}/Payment/webpasspurchase`, body).pipe(
      catchError(this.handlePostError), 
      map(data => data['guid'])
    ))
  }

  async postGiftPassPurchase(
    siteId: string,
    receipt: WNPaymentResponse,
    details: IPassPurchaseDetails,
    customerPassId: string,
    textURL: string,
    thankYouURL: string,
    consumerId?: string
  ) {
    const body: IPostGiftPassPurchase = {
      consumerId: consumerId ?? null,
      customerPassId: customerPassId,
      siteId: siteId,
      wnApprovalCode: receipt.APPROVALCODE.substring(0, 2),
      wnCardNumber: receipt.CARDNUMBER,
      wnCardType: receipt.CARDTYPE,
      wnOrderId: receipt.ORDERID,
      wnResponseCode: receipt.RESPONSECODE,
      wnResponseText: receipt.RESPONSETEXT,
      wnUniqueRef: receipt.UNIQUEREF,
      amount: receipt.AMOUNT,
      promoId: receipt.PROMOID,
      promoCode: receipt.PROMOCODE,
      tax: receipt.TAX,
      surcharge: receipt.SURCHARGE,
      cardReference: receipt.CARDREFERENCE,
      cardExpiry: receipt.CARDEXPIRY,
      textURL: textURL,
      thankYouURL: thankYouURL,
      giftEmail: details.emailAddress,
      activationDate: details.activationDate,
      mobileNumber: receipt.PHONE,
      fromName: details.fromName,
      recipientNumber: receipt.RECIPIENTPHONE,
      toName: details.toName,
      giftMonths: details.monthsDuration
    };

    return await firstValueFrom(this.http.post(`${this.urlBase}/Payment/webgiftpasspurchase`, body).pipe(
      catchError(this.handlePostError),
      map(data => data['guid'])
    ))
    
  }
  
  fetchStaticHTML(
    url: string,
    isTrusted: boolean,
    method: "GET" | "POST" = "GET",
    body?: any
  ): Observable<string> {
    if (method === "POST") {
      return this.http
      .post(url, body, { responseType: "text" })
      .pipe(map((response) => this.mapStaticHtml(response, isTrusted)));
    } else {
      return this.http
      .get(url, { responseType: "text" })
      .pipe(map((response) => this.mapStaticHtml(response, isTrusted)));
    }
  }
  
  private mapStaticHtml(htmlString: string, isTrusted: boolean): string {
    console.log("response:", htmlString)
    return isTrusted
    ? htmlString
    : this.domSanitizer.sanitize(SecurityContext.HTML, htmlString);
  }
  
  activeCustomers(consumerId: string){
    return this.http.get<CustomerSiteInfo[]>(`${this.urlBase}/Customer/${consumerId}/active-customers`);
  }
  
  // Required before upgradeConsumerPass can be called successfully
  setDashboardPass(consumerPassId: string){
    return this.http.post(`${this.urlBase}/Dashboard/setDashboardPass/${consumerPassId}`, {})
  }

  manageMembershipPassPurchase(customerPassId: string, consumerId: string, sendEmail: boolean, siteId: string, licensePlateNum?: string, vehicleNickname?: string){
    console.log("Processing with vehicle:", licensePlateNum, vehicleNickname)
    return this.http.post(`${this.urlBase}/Payment/purchase/pass`, 
      {
        customerPassId: customerPassId,
        consumerId: consumerId,
        sendEmail: sendEmail,
        siteId: siteId,
        licensePlateNum: licensePlateNum,
        vehicleNickname: vehicleNickname
      });
  }

  upgradeConsumerPass(consumerPassId: string, changeToCustomerPassId: string) {
    return this.http.post(`${this.urlBase}/Dashboard/consumerpass/${consumerPassId}/upgradeto/${changeToCustomerPassId}`, {})
  }

  addConsumerPassVehicle(consumerPassId: string, carName:string, licensePlate: string){
    // will need for manage vehicle functionality within wash-pass page, but not on manage-vehicle page
    let bearer = document.cookie;
    bearer = bearer.substring(bearer.indexOf("token=")+6, bearer.indexOf(";", bearer.indexOf("token=")));
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'authorization': 'Bearer ' + bearer
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.post(`${this.urlBase}/ConsumerPass/${consumerPassId}/vehicle/create`, 
      {
        //body
        make: "",
        model: "",
        year: "",
        licensePlate: licensePlate,
        lpConfidence: 100,
        nickname: carName
      },
      requestOptions
    );
  }

  removeConsumerPassVehicle(vehicleId: string){
    let bearer = document.cookie;
    bearer = bearer.substring(bearer.indexOf("token=")+6, bearer.indexOf(";", bearer.indexOf("token=")+6));
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'authorization': 'Bearer ' + bearer
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.delete(`${this.urlBase}/ConsumerPass/${vehicleId}/vehicle/delete`, requestOptions);
  }

  editConsumerPassVehicle(vehicleId: string, newNickname: string, licensePlate: string){
    let bearer = document.cookie;
    bearer = bearer.substring(bearer.indexOf("token=")+6, bearer.indexOf(";", bearer.indexOf("token=")));
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'authorization': 'Bearer ' + bearer
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(`${this.urlBase}/ConsumerPass/${vehicleId}/vehicle/edit`,
      {
        licensePlate: licensePlate,
        make: '',
        model: '',
        year: '',
        nickname: newNickname
      },
      requestOptions
    );
  }

  getCampaignDetails(campaignId: string){
    return this.http.get(`${this.urlBase}/textmarketing/${campaignId}`);  
  }

  getCampaignInstanceDetails(campaignId: string, instanceId: string){
    return this.http.get(`${this.urlBase}/textmarketing/${campaignId}/${instanceId}`);  
  }

  addConsumerPassFromInstance(consumerId: string, customerPassId: string, campaignId: string, instanceId: string, siteId: string){
    let body = {
      ConsumerId: consumerId,
      CustomerPassId: customerPassId,
      CampaignId: campaignId,
      InstanceId: instanceId,
      SiteId: siteId
    }
    return this.http.post<any>(`${this.urlBase}/textmarketing/addconsumerpass`, body);
  }
}