import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { first, firstValueFrom, map, Subject, takeUntil } from "rxjs";
import { ApiService } from "src/services/api.service";
import { CustomerStateService } from "src/services/customer-state.service";
import { MobileService } from "src/services/mobile.service";

@Component({
    selector: 'instance-landing',
    templateUrl: './campaign-instance-landing.component.html',
    styleUrls: ['./campaign-instance-landing.component.scss']
})

export class CampaignInstanceLandingComponent implements OnInit {
    private readonly onDestroy$ = new Subject<void>();
    private readonly specialKeys = new Array<string>('Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'Enter');
    
    public backgroundImage: string = 'url("../../assets/Splash_Background.jpg")';

    public mobileNumber: string = "";
    private noFormatNumber: string = "";
    public phoneErr: string;

    private campaignGuid: string = '';
    private instanceGuid: string = '';
    public campaignDetails: any;
    public instanceDetails: any;

    private siteId: string;

    public errMsg: string;
    public errored: boolean = false;
    public passName: string = "Loading pass...";
    public campaignDescription: string;

    public checked: boolean = false;
    public submitted: boolean = false;
    public expired: boolean = false;
    public redeemed: boolean = false;
    public submissionResponse: string;
    public mobileDownload: string = "";

    isMobile: boolean;
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private apiService: ApiService,
        private mobileService: MobileService,
        private customerStateService: CustomerStateService
        // private loadingService:  // We'll need this once loading service changes are merged into master
    ){
        this.isMobile = this.mobileService.isMobile();
    }

    async ngOnInit(){
        // values for testing
        // this.submitted = true;
        // this.redeemed = true;
        // this.isMobile = true;

        // this.loadingService.pageLoaded = false;\
        this.campaignDetails = await firstValueFrom(this.route.paramMap.pipe(takeUntil(this.onDestroy$), 
            first((map) => map['params']['campaignId'] != null && map['params']['instanceId'] != null), 
            map(async (paramMap) => {
                this.campaignGuid = paramMap['params']['campaignId'];
                this.instanceGuid = paramMap['params']['instanceId'];
                this.siteId = paramMap['params']['siteId'];
                try {
                    return await firstValueFrom(this.apiService.getCampaignDetails(paramMap['params']['campaignId']));
                } catch {
                    this.errored = true;
                    this.submissionResponse = "No pass found.";
                    return null;
                }
        })))
        console.log("Campaign: ", this.campaignDetails);
        if(this.campaignDetails && this.campaignDetails.enabled && this.campaignDetails.textCampaignType === 2){
                this.customerStateService.customerId = this.campaignDetails.customerId;
                this.customerStateService.currentCustomerInfo$.pipe(takeUntil(this.onDestroy$), first(info => info != null)).subscribe(
                    customerInfo =>  {
                      this.backgroundImage = customerInfo ? "url('data:image/svg;base64," + customerInfo.backgroundImage : 'url("../../assets/Splash_Background.jpg")';
                      this.mobileDownload = this.customerStateService.customerInfo.mobileDownload;
                    })
                this.campaignDescription = this.campaignDetails.description;
                this.instanceDetails = this.campaignDetails.textCampaignInstances.find(instance => instance.id == this.instanceGuid);
                if(this.instanceDetails){
                    this.passName = this.instanceDetails.pass.passName;
                    
                    // Check to see if instance has expired
                    let today = new Date(Date.now());
                    if(this.instanceDetails.expirationDate != null && this.instanceDetails.expirationDate < today.toISOString()){
                        this.submitted = true;
                        this.expired = true;
                        this.submissionResponse =  "This offer has expired.";
                    }
                } else {
                    this.errored = true;
                    this.submissionResponse = "This pass is unavailable at this time.";
                }
        } else {
            this.errored = true;
            this.submissionResponse = "This pass is unavailable at this time.";
        }
        // this.loadingService.pageLoaded = true;
    }

    async checkConsumer(){
        this.checked = true;
        if(!(this.noFormatNumber.length == 10 && /[0-9]/.test(this.noFormatNumber))){
            this.phoneErr = this.noFormatNumber.length == 0 ? "Phone number is required." : "Invalid phone number.";
            return;
        } else {
            this.phoneErr = null;
        }

        let prevMember;
        let consumerId = await firstValueFrom(this.apiService.safeCreatePartialAccount(this.customerStateService.customerId, this.noFormatNumber, this.instanceDetails.pass.customerPassId).pipe(map(cons => {
            prevMember = cons.inTextClub;
            return cons.id;
        })));

        if(consumerId){
            try{
                // returns true or false for redeemed or not redeemed
                this.redeemed = await(firstValueFrom(this.apiService.addConsumerPassFromInstance(consumerId, this.instanceDetails.pass.customerPassId, this.campaignGuid, this.instanceGuid, this.siteId)));            
                let customerName = this.customerStateService.customerInfo.name;
                this.submissionResponse = prevMember ? `This number is already registered with the ${customerName} Text Club.` : `Thank you for joining the ${customerName} Text Club!`;
            } catch(err) {
                console.log(err)
                this.errored = true;
                this.submissionResponse = "Something went wrong during processing." + (err.status == 500 ? "\nPlease verify the phone number input was valid." : "") ;
            }
            this.submitted = true;
            // this.loadingService.pageLoaded = true;
        } else {
            this.phoneErr = "Invalid phone number.";
        }
    }

    checkKey(ev: KeyboardEvent){
        const regex = /^[0-9]+$/;
        if(!this.specialKeys.includes(ev.key)){
            if(this.mobileNumber.length < 14){
                if(!regex.test(ev.key)){
                    ev.preventDefault();
                } else {
                    this.checked = false;
                }
            } else {
                ev.preventDefault();
            }
        }
    }

    formatPhone(){
        let num = new Array<string>(14);
        num[0] = '(';
        num[4] = ')';
        num[5] = ' ';
        num[9] = '-'; 

        if(this.mobileNumber.length > 0){
            let tempNum: string = '';
            for(let i=0; i<this.mobileNumber.length; ++i){
                if(/[0-9]/.test(this.mobileNumber[i])){
                    tempNum += this.mobileNumber[i];
                }
            }
            this.noFormatNumber = tempNum;
            for(let j=0; j<tempNum.length; ++j){
                if(j<3){
                    num[j+1] = tempNum[j];
                } else if(j<6) {
                    num[j+3] = tempNum[j];
                } else {
                    num[j+4] = tempNum[j];
                }
            }
            let nullIndex = num.findIndex(i => i == null);
            let len = nullIndex == -1 ? 14 : nullIndex < 7 ? 4 : nullIndex < 11 ? 9 : 14;
            if(nullIndex < 7 && nullIndex != -1){
                num[0] = null;
            }
            let finalNum: string = '';
            for(let i=0; i<len; ++i){
                if(num[i] != null){
                    finalNum += num[i];
                }
            }

            this.mobileNumber = finalNum;
        }
    }

    sendToMobile() {
        window.open(this.mobileDownload, "_blank");
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }
}